@import "normalize.css";

* {
  outline: none !important;
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
}

html {
  font-size: 62.5%;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: rgb(255, 255, 255);
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

.styleDivTable h3 {
  color: #ed7122;
  font-size: 3.4rem;
  margin: 5px;
  font-weight: normal;
}
.styleDivTable {
  padding: 10px;
}
.colorError {
  color: red;
}
.style__importFile {
  width: auto;
  margin-top: 20px;
  border-radius: 5px;
  height: auto;
}
.style__importFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.ColorAddMission {
  color: #ed7122;
  cursor: pointer;
}
.styleTextMission {
  color: #ed7122;
  margin: 10px 10px 20px 10px;
  letter-spacing: 0px;
  font-size: 3.7rem;
}
.styleTextMissionAll {
  color: #ed7122;
  margin: 50% 10px 10px 10px;
  letter-spacing: 0px;
  font-size: 3rem;
  text-align: center;
}
.svgBtnMission {
  width: 90%;
  height: 90%;
  object-fit: cover;
  -o-object-fit: cover;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}
.block_question {
  display: block;
  width: 100%;
  height: auto;
}
.questioncadre {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 10px;
  margin: 5px;
  overflow: hidden;
}

form label {
  z-index: 99;
}
.styleListeManagerTitle {
  margin-left: 15px;
  font-size: 16px;
}
.styleListemanagerNull {
  text-align: center;
  font-size: 18px;
}
.styleOnQuestionnaire {
  margin-bottom: 120px;
  overflow: scroll;
  height: 100vh;
}

.Style_Modale_import {
  margin: 30px 0 10px 0;
}
.Style_text_colorRed_import {
  color: red;
}
.Style_text_colorSuccess_import {
  color: rgb(0, 128, 0, 0.7);
}
.chartActive {
  background: #ed7122;
  color: white;
  outline: none;
}
.chartActive:hover {
  background: #ed7122;
}
._breadCrumbStyle {
  color: #262933;
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-start;
}
._breadCrumb {
  font-size: 1.2rem;
  display: flex;
  color: #ed7122;
  justify-content: flex-start;
}
._breadCrumb p {
  margin: 7px 0 0 2px;
}
._breadCrumbStyle p {
  margin: 7px 0 0 2px;
  color: #262933;
}
._breadCrumbStyle p span {
  color: #ed7122;
}
.buttonStyleChart {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 1.1rem;
  text-transform: none;
}
.__styleHeightWidget {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.style_profil {
  left: 5px;
  width: 100px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: -50px;
}

.__style_imageProfil {
  border: 1px solid white;
  border-radius: 50px;
  height: 100px;
  width: 100px;
  object-fit: cover;
  -o-object-fit: cover;
}

.styleFicheBesoinDescription {
  width: 95%;
  height: auto;
}
.successMailStyle {
  font-weight: normal;
  color: #ed7122;
  font-size: 5rem;
  text-align: left;
  margin-top: 50px;
  text-align: center;
  margin-left: 15px;
}
.successMailStyleText {
  font-size: 3rem;
  text-align: center;
  padding: 5px 0;
}
.styleMailSvg {
  display: block;
  height: 400px;
  padding: 0;
}
.styleMailSvg img {
  width: 100%;
  height: 100%;
}
.makeStyles-item-279 {
  height: 20px !important;
}
.makeStyles-content-202 {
  height: 100vh !important;
}
.styleFlechPerformance__lose {
  color: red;
}
.styleFlechPerformance__success {
  color: green;
}
.styleHiddenTable {
  width: 100%;
  height: auto;
  padding: 10px;
  text-align: center;
  font-size: 20px;
}
.container-horsline {
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
  overflow: hidden;
  padding: 10% 0;
  text-align: center;
}
.container-horsline h3 {
  font-weight: 400;
  font-size: 2.5rem;
}
.container-horsline button {
  width: auto;
  height: auto;
  padding: 10px 15px;
  background: #ed7122;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  color: white;
}
.MuiExpansionPanelDetails-root {
  width: 100%;
}
.MuiExpansionPanelDetails-root table {
  text-indent: initial;
  display: table;
  table-layout: auto !important;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 100%;
  margin: 0 0 10px;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
}
.MuiExpansionPanelDetails-root table td,
.MuiExpansionPanelDetails-root table th {
  border: 1px solid #ccc;
  padding: 0.4em;
  background-clip: padding-box;
}
.MuiTypography-body1 {
  font-size: 1.6rem;
}
.MuiFormControlLabel-label {
  font-size: 20px;
}
.colorTextDashboard {
  color: #8898aa;
  font-weight: 300;
  font-size: 15px;
}
.colorTextDashboardMission {
  color: #ed7122;
  font-weight: 300;
  font-size: 16px;
}
.colorCollapsIcon {
  color: #ed7122;
  font-weight: 300;
}
.StyleHiddenRadio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10000;
}
.cadreLoader {
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0 5px;
}
.cadreLoader img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.styleAnnexe {
  width: 100%;
  height: 400px;
}

.styleDotAlert {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ef5350;
  margin-right: 5px;
}
.styleTitleInvoicing{
  width: 50%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.07);
  text-align: left;
  font-size: 16px;
  padding: 2px 20px 2px 2px;
  font-weight: 400;
}

@import "print.css";
@import "tables.css";
@import "react-table.css";
@import "prism.css";
@import "tailwind.css";
@import "./components/widget.css";
@import "./components/calander.css";
@import "./components/collaborateur.css";
@import "./components/barchartstyle.css";
@import "./components/prospect.css";
@import "./components/styleButton.css";
@import "./components/appStyle.css";
@import "./components/modal.css";
@import "./components/styleModaleAction.css";
@import "./components/questionnaireStyle.css";
@import "./components/budgetisation.css";
@import "./components/ficheBesoinStyle.css";
@import "./components/mission.css";
@import "./components/calendrierColor.css";
@import "./components/dateTimeStyle.css";
@import "react-datetime/css/react-datetime.css";
@import "./components/landing.css";
/* custom style */
